import Swal from 'sweetalert2'
import * as XLSX from 'xlsx';
import moment from 'moment';
import phpUnserialize from "phpunserialize";

export default {
    retornaAtributoArray(array, campo) {
        return array.map((item) => item[campo]);
    },

    returnPgArrayToArray(stringToSplit, separator) {
        const variables = stringToSplit.slice(1, -1);
        return variables.split(separator);
    },

    backStateActiveTableRow(tableItems, itemchanged, idField) {
        tableItems.map((row) => {
            if (row[idField] === itemchanged.itemId) {
                row.ativo = !itemchanged.state;
            }
        });
    },

    changeStateActiveTableRow(tableItems, itemchanged, idField) {
        tableItems.map((row) => {
            if (row[idField] === itemchanged.itemId) {
                row.ativo = itemchanged.state;
            }
        });
    },

    indexablePage() {
        const element = document.getElementById("indexable")
        if (element) {
            element.remove();
        }
    },

    CheckAcronymLetters(formErrors, form) {
        formErrors.acronym = null;

        if (form.acronym.length < 3) {
            formErrors.acronym = "A sigla deve ter ao menos 3 caracteres";
        }

        form.acronym = form.acronym.toUpperCase();
    },

    somenteNumeros(valor) {
        return valor.replace(/\D/g, "");
    },

    modalErroServidorPadrao() {
        Swal.fire({
            title: 'Ocorreu um erro.',
            icon: 'warning',
            iconColor: "red",
            html: '<div class="fonte_corpo_modal"><div>Não se preocupe, os dados estão seguros.</div><div>Por favor, tente novamente ou contate nosso suporte.</div></div>',
            width: '470px',
            confirmButtonText: 'Ok',
            customClass: {
                title: "fonte_titulo_modal",
                confirmButton: "btn btn-custom-blue"
            },
            buttonsStyling: false
        })
    },

    modalErroValidacaoPadrao() {
        Swal.fire({
            title: 'Ocorreu um erro.',
            icon: 'info',
            iconColor: "yellow",
            html: '<div class="fonte_corpo_modal"><div>Erro na validação dos dados.</div><div>Por favor, tente novamente mais tarde ou contate nosso suporte.</div></div>',
            width: '470px',
            confirmButtonText: 'Ok',
            customClass: {
                title: "fonte_titulo_modal",
                confirmButton: "btn btn-custom-blue"
            },
            buttonsStyling: false
        })
    },

    modalErroTermoDeAdesao(infoText) {
        Swal.fire({
            title: 'Ocorreu um erro.',
            icon: 'info',
            iconColor: "yellow",
            html: infoText ?? '<div class="fonte_corpo_modal"><div>Ocorreu um erro no termo de adesão.</div><div>Por favor, tente novamente mais tarde ou contate nosso suporte.</div></div>',
            width: '470px',
            confirmButtonText: 'Ok',
            customClass: {
                title: "fonte_titulo_modal",
                confirmButton: "btn btn-custom-blue"
            },
            buttonsStyling: false
        })
    },

    modalErroEmpresa(infoText) {
        Swal.fire({
            title: 'Ocorreu um erro.',
            icon: 'info',
            iconColor: "#ff9314",
            html: infoText ?? '<div class="fonte_corpo_modal"><div>Ocorreu um erro no salvamento dos dados.</div><div>Por favor, tente novamente mais tarde ou contate nosso suporte.</div></div>',
            width: '470px',
            confirmButtonText: 'Entendido',
            customClass: {
                title: "fonte_titulo_modal",
                confirmButton: "btn btn-custom-blue"
            },
            buttonsStyling: false
        })
    },

    downloadArchive(blob, filename) {
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                let url = URL.createObjectURL(blob),
                    idUnico = new Date()
                        .toISOString()
                        .replaceAll(":", "-")
                        .replaceAll(".", "-"),
                    novoNomeArquivo = idUnico + "_" + filename;

                link.setAttribute("href", url);
                link.setAttribute("download", novoNomeArquivo);
                link.style.visibility = "hidden";

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },

    downloadArchiveCsv(conteudo, filename) {
        let blob = new Blob([conteudo], {
            type: "text/csv;charset=utf-8;",
        });
        this.downloadArchive(blob, filename);
    },

    downloadArchiveXlsx(conteudo, filename, addTimestamp = true) {
        const prefixo = addTimestamp ? moment().format('YYYY_MM_DD_HH:mm:ss') + '-' : '';
        const nomeArquivo = prefixo + filename
        const gerandoDados = XLSX.utils.json_to_sheet(conteudo)
        const planilha = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(planilha, gerandoDados, 'data')
        XLSX.writeFile(planilha, nomeArquivo)
    },

    ehDoTipoComFomento(idIncentivo) {
        return idIncentivo === 2
    },

    findIndexInArray(array, objeto, campo) {
        return array.findIndex(x => x[campo] === objeto[campo]);
    },

    geraNumeroAleatorio() {
        return Math.floor(Math.random() * 999999999999);
    },

    filterByValue(array, objeto, campo) {
        return array.filter(function (el) {
            return el[campo] == objeto[campo]
        });
    },

    getVersionApi(version, route) {
        return `${version}` + route;
    },

    maskEmailForShow(email) {
        const [name, domain] = email.split('@');
    
        if (name.length <= 3) {
            return `${name}***@${domain}`;
        }
    
        return `${name.slice(0, 3)}${'*'.repeat(name.length - 3)}@${domain}`;
    },

    formatStringLowerCaseWithoutAccent(value) {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },

    doNotAllowZeroValue(event, value, valueKey) {
        if (value[valueKey]) { return; }
        if (event.key == '0') { event.preventDefault(); }
    },

    verifyIfCanOpenModalError(idEntity, idEntityRouteParam) {
        if ((idEntity === null || typeof idEntity == 'undefined') && typeof idEntityRouteParam == 'undefined')
            return true;
    },

    verifyIfHasIdEntityRouteParam(idEntity, idEntityRouteParam) {
        if (typeof idEntityRouteParam != "undefined" && idEntityRouteParam !== idEntity)
            return true;
    },

    phpUnserialize(serialized) {
        return phpUnserialize(serialized);
    },

    keyIsNumber(event) {
        const keyCode = event.keyCode;
        if (
            !(keyCode >= 48 && keyCode <= 57) &&
            keyCode !== 46 &&
            keyCode !== 8 &&
            keyCode !== 9
        ) {
            return false
        }
        return true
    },

    converteValorMonetarioStringFloat(valor) {
        let numeroFormatado = valor.replace(/\./g, "").replace(",", ".");

        return parseFloat(numeroFormatado).toFixed(2);
    },

    uniqueArray(arr) {
        const unique = {};
        return arr.filter(item => {
            if (!unique[item]) {
                unique[item] = true;
                return true;
            }
            return false;
        });
    },

    adicionarMascaraCep(cep) {
        if (cep !== null) {
            let cepString = cep.toString();
            return cepString.slice(0, 2) + '.' +
                cepString.slice(2, 5) + '-' +
                cepString.slice(5);
        }
        return '';
    },

    adicionarMascaraCnpj(cnpj) {
        if (cnpj !== null) {
            let cnpjString = cnpj.toString().padStart(14, '0');
            return cnpjString.slice(0, 2) + '.' +
                cnpjString.slice(2, 5) + '.' +
                cnpjString.slice(5, 8) + '/' +
                cnpjString.slice(8, 12) + '-' +
                cnpjString.slice(12, 14);
        }
        return '';
    },

    formatEndereco(endereco) {
        return [
          endereco.logradouro,
          endereco.numero,
          endereco.bairro,
          endereco.nome_cidade,
          endereco.uf,
        ].filter(Boolean).join(', ');
      },
}