const state = {
   uf: null,
   departmentId: null,
}

const mutations = {
    SET_UF(state, data){
        state.uf = data;
    },
    SET_DEPARTMENT_ID(state, data){
        state.departmentId = data;
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
