/* eslint-disable no-shadow */
const state = {
  companyUserView: null,
  companyUserUpdate: null,

  formData: {
    id: '',
    nome: '',
    email: '',
    cpf: '',
    nascimento: '',
    sexo: null,
    perfil: null,
    departamento: null,
    regioes: [],
    unidades: [],
    empresas: [],
    grupos: [],
  },
}

const mutations = {
  setCompanyUserView(state, data) {
    state.companyUserView = data
  },
  setCompanyUserUpdate(state, data) {
    state.companyUserUpdate = data
  },

  setFormData(state, formData) {
    state.formData = {
      id: formData.id,
      nome: formData.nome,
      email: formData.email,
      cpf: formData.cpf,
      nascimento: formData.dataNascimento,
      sexo: formData.sexo,
      perfil: formData.perfil,
      departamento: formData.departamento,
      regioes: formData.regioes,
      unidades: formData.unidades,
      empresas: formData.empresas,
      idNacionalidade: formData.idNacionalidade,
      nomeMae: formData.nomeMae,
      grupos: formData.grupos,
    }
  },

  setCompanies(state, companies) {
    state.formData.empresas = companies
  },

  setGroups(state, groups) {
    state.formData.grupos = groups
  },

  clearFormData(state) {
    state.formData = {
      id: '',
      nome: '',
      email: '',
      cpf: '',
      nascimento: '',
      sexo: null,
      perfil: null,
      departamento: null,
      regioes: [],
      unidades: [],
      empresas: [],
      grupos: [],
    }
  },
}

const getters = {
  getCompanyUserView: state => state.companyUserView,
  getCompanyUserUpdate: state => state.companyUserUpdate,

  getFormData: state => state.formData,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
