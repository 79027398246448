/* eslint-disable no-shadow */
const state = {
  adminUserView: null,
  adminUserUpdate: null,

  formData: {
    id: '',
    nome: '',
    cpf: '',
    dataNascimento: '',
    sexo: null,
    email: '',
    perfil: null,
    departamentoAux: null,
    tipoAtuacao: null,
    regioes: [],
    unidades: [],
    empresas: [],
    vacinadorOption: false,
    permiteEstornarVacinacaoOption: false,
    tipoDocumento: [],
    numeroDocumento: null,
    estadoDocumento: null,
  },
}

const mutations = {
  setAdminUserView(state, data) {
    state.adminUserView = data
  },
  setAdminUserUpdate(state, data) {
    state.adminUserUpdate = data
  },

  setFormData(state, formData) {
    state.formData = {
      id: formData.id,
      nome: formData.nome,
      cpf: formData.cpf,
      nascimento: formData.dataNascimento,
      sexo: formData.sexo,
      email: formData.email,
      perfil: formData.perfil,
      departamento: formData.departamento,
      departamentoAux: formData.departamento,
      regioes: formData.regioes,
      unidades: formData.unidades,
      empresas: formData.empresas,
      tipoAtuacao: formData.tipoAtuacao,
      vacinadorOption: formData.vacinadorOption,
      permiteEstornarVacinacaoOption: formData.permiteEstornarVacinacaoOption,
      tipoDocumento: formData.tipoDocumento,
      numeroDocumento: formData.numeroDocumento,
      estadoDocumento: formData.estadoDocumento,
    }
  },

  setCompanies(state, companies) {
    state.formData.empresas = companies
  },

  clearFormData(state) {
    state.formData = {
      id: '',
      nome: '',
      cpf: '',
      dataNascimento: '',
      sexo: null,
      email: '',
      perfil: null,
      departamento: null,
      departamentoAux: null,
      tipoAtuacao: null,
      regioes: [],
      unidades: [],
      empresas: [],
      vacinadorOption: false,
      permiteEstornarVacinacaoOption: false,
    }
  },
}

const getters = {
  getAdminUserView: state => state.adminUserView,
  getAdminUserUpdate: state => state.adminUserUpdate,

  getFormData: state => state.formData,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
