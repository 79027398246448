import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from "vuex-persistedstate";
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import sessions from "./sessions";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import listFilter from "./listFilter";
import empresaState from "./empresaState";
import unidadeState from "./unidadeState";
import campaignState from "./campaignState";
import vacinacaoState from "./vacinacaoState";
import entradaState from "./entradaState";
import usuarioState from "./usuarioState";
import adesaoState from "./adesaoState";
import vacinaState from "./vacinaState";
import doencaState from "./doencaState";
import departamentoState from "./departamentoState";
import departamentoApiState from "./departamentoApiState";
import pontoAtendimentoState from "./pontoAtendimentoState";
import notifications from "./notifications";
import agendamentoState from "./agendamentoState";
import avisoState from "./avisoState";
import ajudaState from "./ajudaState";
import companyUserState from './companyUserState'
import adminUserState from './adminUserState'
import groupState from './groupState'
import campanhaNacionalState from "./campanhaNacionalState";
import adesaoNacionalState from "./adesaoNacionalState";
import adesaoNacionalViewState from "./adesaoNacionalState/list";
import tourState from './tourState';
import filtrosState from "./filtrosState";
import departmentTemporaryState from "@/store/departmentTemporaryState";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sessions,
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    listFilter,
    empresaState,
    unidadeState,
    campaignState,
    entradaState,
    usuarioState,
    adesaoState,
    vacinaState,
    doencaState,
    departamentoState,
    departamentoApiState,
    pontoAtendimentoState,
    notifications,
    agendamentoState,
    avisoState,
    ajudaState,
    companyUserState,
    adminUserState,
    groupState,
    campanhaNacionalState,
    adesaoNacionalState,
    adesaoNacionalViewState,
    vacinacaoState,
    tourState,
    filtrosState,
    departmentTemporaryState
  },
  plugins: [
    createPersistedState({
      paths: [
        'sessions',
        'listFilter',
        'empresaState',
        'unidadeState',
        'campaignState',
        'entradaState',
        'adesaoState',
        'usuarioState',
        'vacinaState',
        'doencaState',
        'departamentoState',
        'departamentoApiState',
        'pontoAtendimentoState',
        'avisoState',
        'ajudaState',
        'companyUserState',
        'adminUserState',
        'groupState',
        'vacinacaoState',
        'adesaoNacionalViewState',
        'tourState',
        'filtrosState',
        'departmentTemporaryState'
      ],
    }),
  ],
  strict: process.env.DEV,
})
