import provider from '@/store'
import { getUserData } from "@/auth/utils";

export const storeAtualizarDepartamento = updated => {
    const { departamento } = getUserData();

    departamento.descricao = updated.nameDepartamento;
    departamento.sigla = updated.acronym;
    departamento.uf = updated.ufSelected;

    provider.commit("sessions/SET_DEPARTMENT", departamento);
}

export const storeAtualizarDivisaoPorRegiao = divisaoPorRegiao => {
    const { departamento } = getUserData();

    departamento.divisao_por_regiao = divisaoPorRegiao;

    provider.commit("sessions/SET_DEPARTMENT", departamento);
}

export const setListFilter = data => {
    provider.commit("listFilter/SET_LIST_FILTER", data);
}

export const setIdEmpresa = data => {
    provider.commit("empresaState/SET_ID_EMPRESA", data);
}

export const setEstadosEnderecoEmpresa = data => {
    provider.commit("empresaState/SET_ESTADOS_ENDERECO_EMPRESA", data);
}

export const setCidadeEstadoEnderecoEmpresa = data => {
    provider.commit("empresaState/SET_CIDADE_ESTADOS_ENDERECO_EMPRESA", data);
}

export const setModalValorDiferenciadoRemover = data => {
    provider.commit("empresaState/SET_MODAL_VALOR_DIFERENCIADO_REMOVER", data);
}


export const setIdUnidade = data => {
    provider.commit("unidadeState/SET_ID_UNIDADE", data);
}

export const setIdCampaign = data => {
    provider.commit("campaignState/SET_ID_CAMPAIGN", data);
}

export const setDescricaoCampanha = data => {
    provider.commit("campaignState/SET_DESCRIPTION_CAMPAIGN", data);
}

export const setIdCampaignEditAndShow = data => {
    provider.commit("campaignState/SET_ID_CAMPAIGN_EDIT_AND_SHOW", data);
}

export const setRegionConsulted = (idRegion, units = []) => {
    provider.commit("campaignState/SET_REGION_CONSULTED", { idRegion, units });
}

export const setIdNotaFiscal = data => {
    provider.commit("entradaState/SET_ID_NOTA_FISCAL", data);
}

export const setIdLote = data => {
    provider.commit("entradaState/SET_ID_LOTE", data);
}

export const setIdUsuario = data => {
    provider.commit("usuarioState/SET_ID", data);
}

export const setEmailForgotPassword = data => {
    provider.commit("usuarioState/SET_EMAIL_FORGOT_PASSWORD", data);
}

export const setUserCompanyList = data => {
    provider.commit("usuarioState/SET_EMPRESA_LIST", data);
}

export const addEmpresaToList = data => {
    provider.commit("usuarioState/ADD_EMPRESA_TO_LIST", data);
}

export const setIdVacina = data => {
    provider.commit("vacinaState/SET_ID_VACINA", data);
}

export const setIdDoenca = data => {
    provider.commit("doencaState/SET_ID_DOENCA", data);
}

export const setIdDepartamento = data => {
    provider.commit("departamentoState/SET_ID_DEPARTAMENTO", data);
}

export const setAcronymDepartamento = data => {
    provider.commit("departamentoState/SET_ACRONYM_DEPARTAMENTO", data);
}

export const setAcronymUnidade = data => {
    provider.commit("unidadeState/SET_ACRONYM_UNIDADE", data);
}

export const setIdPontoAtendimento = data => {
    provider.commit("pontoAtendimentoState/SET_ID_PONTO_ATENDIMENTO", data);
}

export const setDescricaoPontoAtendimento = data => {
    provider.commit("pontoAtendimentoState/SET_DESCRICAO_PONTO_ATENDIMENTO", data);
}

export const setIdAgendamento = data => {
    provider.commit("agendamentoState/SET_ID_AGENDAMENTO", data)
}

export const setIdAviso = data => {
    provider.commit("avisoState/SET_ID_AVISO", data);
}

export const setPerfisSelecionadosAviso = data => {
    provider.commit("avisoState/SET_PERFIS_SELECIONADOS", data);
}

export const setIdAjuda = data => {
    provider.commit("ajudaState/SET_ID_AJUDA", data);
}

export const setIdCategoria = data => {
    provider.commit("ajudaState/SET_ID_CATEGORIA", data);
}

export const setDescricaoCategoria = data => {
    provider.commit("ajudaState/SET_DESCRICAO_CATEGORIA", data);
}

export const setIdCompanyGroup = data => {
    provider.commit("groupState/SET_ID_COMPANY_GROUP", data)
}

export const setCompanyGroup = data => {
    provider.commit("groupState/SET_COMPANY_GROUP_STATE", data)
}

export const setCompanyGroupArray = data => {
    provider.commit("groupState/SET_COMPANY_GROUP_ARRAY_STATE", data)
}

export const setCompanyGroupFilter = data => {
    provider.commit("groupState/SET_COMPANY_GROUP_FILTER_STATE", data)
}

export const resetCompanyGroup = () => {
    provider.commit("groupState/RESET_COMPANY_GROUP")
}

export const setCompanyEditWhenCreatingGroup = data => {
    provider.commit("groupState/SET_COMPANY_EDIT_WHEN_CREATING_GROUP", data)
}

export const resetCompanyEditWhenCreatingGroup = () => {
    provider.commit("groupState/RESET_COMPANY_EDIT_WHEN_CREATING_GROUP")
}

export const setCompanyEditWhenEditingGroup = data => {
    provider.commit("groupState/SET_COMPANY_EDIT_WHEN_EDITING_GROUP", data)
} 

export const resetCompanyEditWhenEditingGroup = () => {
    provider.commit("groupState/RESET_COMPANY_EDIT_WHEN_EDITING_GROUP")
} 

export const setCompanyEditIdEmpresa = data => {
    provider.commit("groupState/SET_COMPANY_EDIT_ID_EMPRESA", data)
}

export const resetCompanyEditIdEmpresa = () => {
    provider.commit("groupState/RESET_COMPANY_EDIT_ID_EMPRESA")
}

export const setCompanyWithOperators = data => {
    provider.commit("groupState/SET_COMPANY_WITH_OPERATORS", data)
}

export const setTourMode = data => {
    provider.commit("tourState/SET_TOUR_MODE", data)
}

export const setTourSteps = (data, currentStep = 0) => {
    provider.commit("tourState/SET_STEPS", data)
    provider.commit("tourState/SET_CURRENT_STEP", currentStep)
}

export const setTourCurrentStep = data => {
    provider.commit("tourState/SET_CURRENT_STEP", data)
}

export const setTourModalType = data => {
    provider.commit("tourState/SET_MODAL_TYPE", data)
}

export const setTourWatchingTopic = data => {
    provider.commit("tourState/SET_WATCHING_TOPIC", data)
}

export const setTourWatchingAllTutorials = data => {
    provider.commit("tourState/SET_WATCHING_ALL_TUTORIALS", data)
}

export const setCompanyBusiness = data => {
    provider.commit("groupState/SET_COMPANY_BUSINESS", data)
}

export const setCompanyBusinessId = data => {
    provider.commit("groupState/SET_COMPANY_BUSINESS_ID", data)
}

export const resetCompanyBusiness = () => {
    provider.commit("groupState/RESET_COMPANY_BUSINESS")
}

export const resetCompanyBusinessId = () => {
    provider.commit("groupState/RESET_COMPANY_BUSINESS_ID")
}

export const setDepartmentTemporaryUf = data => {
    provider.commit("departmentTemporaryState/SET_UF", data)
}

export const setDepartmentTemporaryId = data => {
    provider.commit("departmentTemporaryState/SET_DEPARTMENT_ID", data)
}